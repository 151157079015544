export default {
  generic: {
    welcome: 'Welcome',
    login: 'Login',
    support: 'Support',
    logout: 'Logout',
    viewAll: 'View All',
    loading: 'Loading...',
    viewAllJobs: 'All jobs',
    openFacebook: 'Get help from Facebook Page',
    openMessenger: 'Write as via Facebook Messager',
    openEmail: 'Write us',
    accept: 'Accept',
    postCode: 'PostCode',
    city: 'City',
    street: 'Street',
    streetNumber: 'Street number',
    cancel: 'Cancel',
    save: 'Save',
    OK: 'OK',
    create: 'Create',
    email: 'Email',
    edit: 'Edit',
    details: 'Details',
    name: 'Name',
    phoneNumber: 'PhoneNumber',
    confirm: 'Confirm',
    resend: 'Resend',
    hourly: 'Hourly',
    total: 'Total',
    rake: 'Rake: ',
    commission: 'Commission: ',
    invoiced: 'Invoiced: ',
    personalTax: 'Personal tax: ',
    studentGross: 'Gross wage for worker: ',
    studentNet: 'Net wage of worker: ',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    navigateToEntity: 'Navigate to entity',
    openDetails: 'Open details',
    balance: 'Balance',
    yes: 'yes',
    no: 'no',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
  },
  menu: {
    home: 'Home',
    join: 'Join requests',
    delete: 'Account delete requests',
    companies: 'Companies',
    workerProfiles: 'Worker Profiles',
    managerProfiles: 'Manager Profiles',
    jobs: 'Jobs',
    jobConfirmations: 'Job confirmations',
    transactions: 'Transactions',
    invoices: 'Invoices',
    invoiceProblems: 'Invoice problem',
    managerInvites: 'Manager invites',
    logs: 'Logs',
    workerFunctions: 'Worker',
    jobFunctions: 'Job',
    financialFunctions: 'Financial',
    managerFunctions: 'Manager',
    xmlFunctions: 'ANYK XML',
    newStudentNotifier: 'New Student Notifier',
  },
  alerts: {
    managerProfileDetailsScreen: {
      error: 'Failed to get manager profile',
    },
    managerProfileListScreen: {
      error: 'Failed to get manager profiles',
      errorToken: 'Failed to get the token',
    },
    workerProfileDetailsScreen: {
      error: 'Failed to get worker profile',
    },
    workerProfileListScreen: {
      error: 'Failed to get worker profiles',
    },
    workerProfileXMLGenerate: {
      error: 'Failed to generate the XML',
      cantDownload: 'XML can not be downloaded',
    },
    companyDetailsScreen: {
      error: 'Failed to get worker profile',
    },
    createInvoiceHistory: {
      error: 'Failed to create invoice for company',
      success: 'Invoice created successfully',
    },
    managerInviteScreen: {
      error: 'Failed to get manager invites',
      success: 'Resend success',
    },
    jobConfirmationsScreen: {
      error: 'Failed to get job confirmations',
    },
    jobConfirmationDetailScreen: {
      error: 'Failed to get job confirmation',
    },
    jobConfirmationClose: {
      error: 'Failed to close job confirmation',
      success: 'Successfully closed job confirmation',
    },
    transactionDetailScreen: {
      error: 'Failed to get transaction details',
    },
    managerInvite: {
      success: 'Manager invited successfully',
      error: 'Inviting manager failed',
      warning: 'Manager already invited',
    },
    inviteWorkerToJob: {
      success: 'Invite success',
      error: 'Invite failed',
    },
    workerDocuments: {
      success: 'Successfully updated document',
      error: 'Document update failed',
    },
    invoicesScreen: {
      error: 'Failed to get invoices',
    },
    invoiceUpdate: {
      success: 'Successfully added transaction to invoice',
      error: 'Failed to update the invoice',
    },
    invoicePublicUrl: {
      error: 'Failed to get the download link for the invoices',
    },
    notClosedJobConfirmation: {
      error: 'Company has not closed job confirmations! Close them before creating an invoice!',
    },
    jobConfirmationHistory: {
      error: 'Failed to get history for confirmation',
    },
    logList: {
      error: 'Failed to get logs',
    },
    joinRequest: {
      error: 'Failed to get join requests',
    },
    acceptJoinRequest: {
      success: 'Successfully accepted join request',
      error: 'Accepting join request failed',
    },
    account: {
      error: 'Failed to fetch company account',
    },
    transaction: {
      success: 'Transaction added successfully',
      error: 'Adding transaction failed',
    },
  },
  validation: {
    required: 'Required',
    minLength: 'Must be at least {{number}} long',
    minPrice: 'Cannot be less than the full price of the invoice, which is: {{minPrice}}',
    invalidEmail: 'Invalid email address',
  },
  loginScreen: {
    title: 'Della Admin',
    email: 'Your email address',
    password: 'Your password',
    networkError: 'Network error, can not connect to the server',
    incorrectAuth: 'Incorrect password or username',
  },
  joinRequestsScreen: {
    title: 'Worker Join Request List',
    headers: {
      name: 'Name',
      status: 'Status',
      phoneNumber: 'PhoneNumber',
      createdAt: 'Created At',
    },
  },
  accDeleteScreen: {
    title: 'Worker Delete Request List',
    headers: {
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      createdAt: 'Created At',
      deletionDate: 'When deletion was requested',
    },
  },
  managerProfileDetailsScreen: {
    title: "{{name}}'s Profile",
    buttonLabel: 'Save',
    collapseButtonLabel: "Manager's companies",
    noCompanies: 'Manager does not have any companies',
    terminate: 'Terminate manager',
    confirmUpdate: 'Do you want to update the manager with the below data?',
    form: {
      id: 'Id',
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      createdAt: 'Created',
      companyId: 'Company Id',
      companyName: 'Company Name',
      companyStatus: 'Company Status',
      country: 'Country',
      city: 'City',
      address: 'Address',
      postCode: 'Postcode',
      rake: 'Rake',
    },
  },
  managerProfilesScreen: {
    title: 'Manager Profile List',
    inviteButtonLabel: 'Invite manager',
    headers: {
      id: 'Id',
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      createdAt: 'Created At',
    },
  },
  workerProfileDetailsScreen: {
    title: "{{name}}'s Profile",
    buttonLabel: 'Save',
    account: 'Account',
    terminate: 'Terminate worker',
    documents: 'Worker documents',
    noCompanies: 'Worker does not have any companies',
    workerCompanies: "Worker's companies",
    workerJobs: "Worker's jobs",
    noJobs: 'Worker does not have any jobs',
    confirmUpdate: 'Do you want to update the worker with the below data?',
    jobConfirmations: "Worker's job confirmations",
    noConfirmation: 'Worker does not have any job confirmations',
    form: {
      id: 'Id',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      birthName: 'Birth Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      birthDay: 'Birthday',
      mothersFirstName: "Mother's First Name",
      mothersLastName: "Mother's Last Name",
      status: 'Status',
      studentId: 'StudentId Number',
      taxNumber: 'Tax number',
      socailsecurityNumber: 'Social security number',
      hasPaidStudentOrg: 'Student organization fee status',
      companyId: 'Company id',
      companyName: 'Company name',
      jobId: 'Job id',
      jobName: 'Job name',
      createdAt: 'Created',
      jobConfirmationId: 'Job Confirmation id',
      jobConfirmationStatus: 'Status',
      documents: 'Uploaded Documents',
      documentStatus: 'Document Status',
      address: 'Address',
      nationality: 'Nationality',
      gender: 'Gender',
      birthPlace: 'Birth Place',
      postCode: 'PostCode',
      city: 'City',
      addressLine1: 'Street & number',
    },
  },
  workerProfilesScreen: {
    title: 'Worker Profile List',
    headers: {
      id: 'Id',
      name: 'Name',
      email: 'Email',
      phoneNumber: 'Phone number',
      isDeclared: 'Bejelentett?',
      status: 'Status',
      createdAt: 'Created At',
    },
    actions: {
      generateXml: 'Generate XML',
      generateForeignPDFDelcaration: 'Generate FG PDF',
      recordDeclaration: 'Add declaration',
    },
  },
  companyListScreen: {
    title: 'Company list',
    headers: {
      id: 'Id',
      name: 'Name',
      rake: 'Rake',
      status: 'Status',
      vatNumber: 'Vat Number',
    },
  },
  companyDetailsScreen: {
    title: "{{name}}'s Profile",
    buttonLabel: 'Save',
    account: 'Account',
    invoicing: 'Invoicing',
    workerPool: 'Worker pool',
    managerPool: 'Managers',
    noWorker: 'Company does not have a worker',
    roles: 'Roles',
    noRole: 'Company does not have a role',
    confirmations: 'Confirmations',
    enableConfirmations: 'Enable confirmations?',
    noConfirmations: 'Company does not have a confirmation',
    confirmUpdate: 'Do you want to update the company with the below data?',
    form: {
      id: 'Id',
      name: 'Name',
      address: 'Address',
      city: 'City',
      country: 'Country',
      postCode: 'PostCode',
      email: 'Primary email',
      number: 'Number',
      rake: 'Rake',
      ceoName: 'CEO name',
      ceoId: 'CEO id',
      ceoPhoneNumber: 'CEO phone number',
      vatNumber: 'VAT number',
      createdAt: 'Created',
      workerName: 'Worker name',
      workerId: 'Worker Id',
      managerName: 'Manager name',
      managerId: 'Manager Id',
      roleId: 'Role Id',
      roleNameHU: 'Role name (HU)',
      roleNameEN: 'Role name (EN)',
      confirmationId: 'Confirmation id',
    },
  },
  managerInvitesScreen: {
    title: 'Manager invite list',
    headers: {
      id: 'Id',
      name: 'Name',
      email: 'email',
      acceptedAt: 'Accepted at',
      status: 'Status',
      company: 'Company',
      createdAt: 'Created At',
    },
    modal: {
      title: 'Invite manager',
      description: 'Do you want to invite a manager?',
    },
  },
  jobsScreen: {
    title: 'Job list',
    headers: {
      id: 'Id',
      hourlyWage: 'Hourly Wage',
      monthlyHours: 'Monthly Hours',
      status: 'Status',
      company: 'Company',
      roleNameHU: 'Role name (HU)',
      roleNameEN: 'Role name (EN)',
      workerCount: 'Needed workercount',
    },
  },
  jobDetailsScreen: {
    title: '{{name}}',
    buttonLabel: 'Save',
    terminate: 'Terminate Job',
    inviteWorker: 'Invite Worker',
    workerPool: 'Worker pool',
    managerPool: 'Managers',
    noWorker: 'Company does not have a worker',
    roles: 'Roles',
    noRole: 'Company does not have a role',
    confirmations: 'Confirmations',
    noConfirmations: 'Company does not have a confirmation',
    confirmUpdate: 'Do you want to update the company with the below data?',
    form: {
      id: 'Id',
      roleNameHU: 'Role name (HU)',
      roleNameEN: 'Role name (EN)',
      hourlyGrossWage: 'Invoiced hourly wage',
      hourlyNetAmount: 'Hourly net amount',
      hourlyGrossAmount: 'Hourly gross amount',
      hourlyCommissionAmount: 'Hourly commission amount',
      hourlyPersonalTaxAmount: 'Hourly personal tax amount',
      invoicedHourlyAmount: 'Invoiced hourly amount',
      monthlyHours: 'Monthly hours',
      weeklyHours: 'Weekly hours',
      startDate: 'Start Date',
      endDate: 'End Date',
      status: 'Status',
      roleDescription: 'Role description',
      roleLocation: 'Role Location',
      roleStatus: 'Role Status',
      companyId: 'Company id',
      companyName: 'Company name',
      createdAt: 'Created',
      jobType: 'Munka típusa',
      schedule: 'Időbeosztás',
      workerCount: 'Szükséges létszám',
      hungarian: 'Magyar',
      english: 'Angol',
      recruitmentType: 'Szükséges-e toborzás?',
      timeSchedule: 'Mennyi időt kell dolgozni? Melyik napokon? Mikor?',
      startNow: 'Azonnal kezdhető',
      girlsAndBoys: 'fiúk és lányok jelentkezését egyaránt várjuk.',
      girls: 'lányok jelentkezését várjuk.',
      boys: 'fiúk jelentkezését várjuk.',
      language: 'Nyelvtudás',
      hungarianSkills: 'Magyar nyelvtudás szükséges a munka elvégzéséhez',
      drivingLicense: 'Jogosítvány szükséges a munka elvégzéséhez',
      documents: 'A munkavégzéshez szükséges dokumentumok',
      documentsPlaceholder: 'pl. Egeszségügyi kiskönyv, tüdőszűrő',
      extraSkills: 'Szükséges készségek, tapasztalat és egyéb elvárások',
      noLicense: 'Nem szükséges',
      car: 'Autó',
      motorCycle: 'Motor',
      englishSkills: 'Angol nyelvtudás szükséges a munka elvégzéséhez',
      bonus: 'Extrák a diákoknak:',
      contactName: 'Contact name',
      contactPhone: 'Phonenumber',
      contactEmail: 'Email',
      equipmentInfo: 'How should the worker arrive?',
    },
    inviteWorkerModal: {
      title: 'Invite worker for a job',
      contentText: 'Select the workers you want to invite for the below job.',
      contentText2: 'Select the manager on behalf you want to invite the workers.',
      job: 'Job name: {{jobName}}',
    },
  },
  workersScreen: {
    invitedWorkerList: {
      invitedWorkerList: 'Diákok listája',
      emptyMessage:
        '*(Jelenleg még nincsenek meghívott diákok. Kattints a diák meghívása gombra a meghívó kiküldéséhez.)',
      pendingInvite: 'Elfogadásra vár',
      acceptedInvite: 'Visszaigazolt',
      confirmDelete: 'Biztosan törölni szeretnéd ezt a diákot a listádról?',
      resendInvite: {
        success: 'Meghívó újra elküldve',
        error: 'Meghívó elküldése nem sikerült',
      },
    },
  },
  jobConfirmationsScreen: {
    title: 'Job Confirmation List',
    invoiced: 'INVOICED',
    notInvoiced: 'NOT INVOICED',
    headers: {
      id: 'Id',
      status: 'Status',
      closedBy: 'Closed by',
      company: 'Company',
      job: 'Job',
      worker: 'Worker',
      createdAt: 'Created At',
      invoice: 'Invoicing status',
    },
  },
  jobConfirmationDetailsScreen: {
    title: '{{id}} confirmation',
    buttonLabel: 'Save',
    close: 'Close Confirmation',
    checkWages: 'Check wages',
    history: 'History',
    status: 'Status',
    pending: 'Pending on {{connectionType}}',
    confirmed: '{{connectionType}} confirmed on {{date}}',
    closed: 'Closed on {{date}} by {{closedBy}}',
    confirmClose: 'Do you really want to close the confirmation?',
    managerDidNotConfirm: 'Manager has not yet confirmed',
    workerDidNotConfirm: 'Worker has not yet confirmed',
    invoiced: 'Invoiced',
    notInvoiced: 'Not invoiced',
    wages: {
      hourly: {
        invoiced: 'Invoiced hourly wage',
        commission: 'Invoiced hourly commission',
        studentGross: 'Invoiced hourly gross wage',
        personalTax: 'Invoiced hourly personal tax',
        studentNet: 'Invoiced hourly net wage',
      },
      total: {
        invoiced: 'Invoiced total wage',
        commission: 'Invoiced total commission',
        studentGross: 'Invoiced total gross wage',
        personalTax: 'Invoiced total personal tax',
        studentNet: 'Invoiced total net wage',
      },
      modal: {
        title: 'These are the calculated wages of the job confirmation:',
      },
    },
    form: {
      id: 'Confirmation id',
      jobId: 'Job id',
      closedBy: 'Closed by',
      companyId: 'Company id',
      companyName: 'Company name',
      workerId: 'Worker id',
      workerName: 'Worker name',
      month: 'Month',
      createdAt: 'Created',
      updatedAt: 'Updated',
      hoursWorked: 'Hours worked',
      hourlyGrossWage: 'Invoiced hourly gross wage',
      invoiceId: 'Invoice id',
      managerConfirmedAt: 'Manager confirmed at',
      workerConfirmedAt: 'Worker confirmed at',
    },
    modal: {
      title: 'Update job confirmation',
      subTitle:
        'Do you really want to update the hours worked? It will affect the below charges this way:',
    },
    historyModal: {
      title: 'Confirmation history',
      acceptedHours: 'Accepted hours: {{hours}}',
    },
  },
  transactionListScreen: {
    title: 'Transaction list',
    headers: {
      id: 'Id',
      amount: 'Amount',
      worker: 'Worker',
      company: 'Company',
      type: 'Transacion type',
      createdAt: 'Created At',
    },
  },
  transactionDetailsScreen: {
    title: 'Transaction id: {{id}}',
    addTransaction: 'Add transaction',
    form: {
      id: 'Id',
      description: 'Description',
      amount: 'Amount',
      createdAt: 'Created at',
      accountId: 'Account id',
      type: 'Transaction type',
      accountBalance: 'Account Balance',
      accountName: 'Account name',
      companyName: 'Company name',
      companyId: 'Company id',
      workerName: 'Worker name',
      workerId: 'Worker Id',
    },
  },
  workerDocumentsScreen: {
    title: "Worker's documents",
    noDocuments: 'Worker has no documents',
    buttonLabel: 'Save',
    noExpiry: 'No expiration date is set',
    confirmUpdate: 'Do you really want to update the Document?',
    form: {
      id: 'Id',
      type: 'Document type',
      status: 'Document status',
      expiresIn: 'Expires in',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
    },
  },
  invoicessScreen: {
    title: 'Invoice List',
    headers: {
      id: 'Id',
      payout: 'Paid',
      amount: 'Amount',
      month: 'Month',
      year: 'Year',
      company: 'Company',
      createdAt: 'Created At',
    },
  },
  invoiceDetailsScreen: {
    title: 'Invoice id: {{id}}',
    buttonLabel: 'Add payment',
    download: 'Download Invoice',
    jobConfirmations: 'Job Confirmations',
    noConfirmation: 'No Confirmation belongs to this invoice',
    transactions: 'Transactions for invoice',
    noTransfer: 'There are no transactions for this invoice',
    transactionId: 'Transaction id',
    transactionDate: 'Date of transaction',
    transactionAmount: 'Amount of transation',
    form: {
      id: 'Id',
      companyId: 'Company Id',
      companyName: 'Company Name',
      amount: 'Amount',
      month: 'Month',
      year: 'Year',
      paid: 'Paid',
      jobConfirmationId: 'Job Confirmation Id',
      createdAt: 'Created At',
    },
    modal: {
      title:
        'In order to update the invoice and create a transaction please give us the below data:',
      subTitle:
        'You have to put NET amount here. In Billingo it will appear as GROSS. (NET * VAT RATE)',
      form: {
        date: 'Date of payment',
        pricePaid: 'Net Price paid',
        paymentMethod: 'Payment method',
      },
    },
  },
  companyInvoiceHistory: {
    title: 'Invoice history',
    buttonLabel: 'Create invoice',
    monthlyInvoice: 'Monthly invoice',
    history: 'History',
    outstanding: 'Outstanding',
    noInvoice: ' No job confirmations or invoices found for the given month and year.',
    noOutstanding: 'No outstanding (not invoiced) confirmations are found for the company.',
    form: {
      jobConfirmationId: 'Job confirmation Id',
      invoiced: 'Invoiced',
      invoiceId: 'Invoice Id',
      createdAt: 'Created At',
    },
    modal: {
      title: 'Are you sure you want to create an invoice for the following confirmations?',
    },
  },
  logList: {
    title: 'Log list',
    headers: {
      id: 'Id',
      info: 'Log info',
      type: 'Type',
      createdAt: 'Created At',
    },
  },
  companyAccount: {
    title: 'Company Account',
    addTransaction: 'Add transaction',
    balance: 'Total Balance: {{balance}} Ft',
    transaction: 'Transactions',
    amount: 'Amount: {{amount}} FT',
    date: 'Date: {{createdAt}}',
    description: 'Description: {{description}}',
    id: 'Id: {{id}}',
    modal: {
      title: 'Add transaction',
      subTitle: 'Be deliberate 😮',
      amount: 'Amount*',
      description: 'Transaction description*',
      metaData: 'Transaction meta data',
    },
  },
  workerAccount: {
    title: 'Worker Account',
    addTransaction: 'Add transaction',
    balance: 'Total Balance: {{balance}} Ft',
    transaction: 'Transactions',
    amount: 'Amount: {{amount}} FT',
    date: 'Date: {{createdAt}}',
    description: 'Description: {{description}}',
    id: 'Id: {{id}}',
    modal: {
      title: 'Add transaction',
      subTitle: 'Be deliberate 😮',
      amount: 'Amount*',
      description: 'Transaction description*',
      metaData: 'Transaction meta data',
    },
  },
};
